<template>
    <default-layout>
        <Tabs />
        <form @submit.prevent="save">
            <div class="field">
                <label class="label">Name</label>
                <div class="control">
                    <input
                        type="text"
                        class="input"
                        maxlength="50"
                        required
                        v-model="form.name"
                    />
                </div>
            </div>
            <div class="field">
                <label class="label">Email</label>
                <div class="control mb-3">
                    <input
                        type="email"
                        class="input"
                        maxlength="255"
                        required
                        v-model="form.email"
                    />
                </div>
                <article class="message is-warning">
                    <div class="message-body">
                        <b-icon icon="info-circle"></b-icon>
                        This must be a <strong>Gmail</strong> or
                        <strong>Google Workplace</strong>
                        email address.
                    </div>
                </article>
            </div>
            <div class="field">
                <label class="label">Organization</label>
                <div class="control">
                    <input
                        v-if="user.roles.owner"
                        type="text"
                        class="input"
                        maxlength="50"
                        required
                        v-model="form.accountName"
                    />
                    <span v-else>{{ user.accountName }}</span>
                </div>
            </div>
            <div class="field">
                <b-button native-type="submit" type="is-primary">
                    Save
                </b-button>
            </div>
        </form>
    </default-layout>
</template>

<script>
import Tabs from './Tabs'
import { mapGetters } from 'vuex'

export default {
    name: 'Profile',
    components: {
        Tabs,
    },
    data() {
        return {
            form: {},
        }
    },
    computed: {
        ...mapGetters({ user: 'account/user' }),
    },
    async created() {
        const { name, email, roles, accountName } = this.user
        this.form = {
            name,
            email,
        }
        if (roles.owner) {
            this.form.accountName = accountName
        }
    },
    methods: {
        async save() {
            this.$loading.show()
            const { form } = this
            const { success, message } = await this.$api.post(
                '/v1/account/user',
                form
            )
            if (success) {
                this.$store.dispatch('account/updateAuthUser', form)
                this.$toast.success('Saved')
            } else {
                this.$toast.error(message)
            }
            this.$loading.hide()
        },
    },
}
</script>
